<template>
    <!-- 新建账户信息页面 -->   
    <el-form :model="AddData" :rules="rules" ref="AddData" size="small" label-width="130px" v-loading="loading" element-loading-text = "新增围栏中...">
        <!-- 头部开始 -->
        <el-row class="pur-top">
        <el-col :span="3"><span class="pur-size">{{ purTitle }}</span></el-col>
        <el-col :span="21" class="pur-right">
            <el-button size="small" @click="AddDatasubmitForm('AddData')">保存</el-button>
            <el-button size="small" style="margin-left: 24px"  @click="Addopen">取消</el-button>
        </el-col>
        </el-row>
        <!-- 头部结束 -->
        <!-- 内容开始 -->
        <div class="pur-nav">
            <!-- 表单 -->
            <div class="pur-table">
                <el-row>
                    <!-- 基础信息 -->
                    <el-col :span="24">
                        <el-row>
                            <el-form-item label="围栏类型" prop="fenceType">
                                <el-col :span="7">
                                    <el-select v-model="AddData.fenceType" placeholder="请选择围栏类型" style="width:100%;" disabled>
                                        <el-option
                                        v-for="item in fenceTypeoptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item label="路线名称" prop="routeName">
                                <el-col :span="7">
                                    <el-input v-model="AddData.routeName" placeholder="请输入路线名称"></el-input>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item label="起始点类型" prop="mapType">
                                <el-col :span="7">
                                    <el-select v-model="AddData.mapType" placeholder="请选择起始点类型" style="width:100%;" @change="qkmap">
                                        <el-option
                                        v-for="item in mapoptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row >
                            <el-form-item label="起点名称" prop="qsaddress">
                                <el-col :span="12">
                                    <el-input v-if="AddData.mapType == 0" v-model="AddData.startPoint" placeholder="请输入起点关键词">
                                        <i slot="prefix" class="el-icon-location-outline" style="color:rgba(111, 207, 151, 1);"></i>
                                    </el-input>
                                    <el-input v-if="AddData.mapType == 1" v-model="AddData.startlng" placeholder="请输入起点经纬度" @blur="starclick">
                                        <i slot="prefix" class="el-icon-location-outline" style="color:rgba(111, 207, 151, 1);"></i>
                                    </el-input>
                                    <div class="block" v-if="AddData.mapType == 0">
                                        <span class="demonstration">圆形围栏范围({{valuestartmap*100}}米)</span>
                                        <el-slider v-model="valuestartmap" :format-tooltip="formatTooltip" :max="10" :min="1" style="display: inline-block;width:70%;"></el-slider>
                                    </div>
                                    <div class="block" v-if="AddData.mapType == 1">
                                        <span class="demonstration">圆形围栏范围({{valuestartmap*100}}米)</span>
                                        <el-slider v-model="valuestartmap" :format-tooltip="formatTooltip" :max="10" :min="1" style="display: inline-block;width:70%;"></el-slider>
                                    </div>
                                    <div v-if="AddData.mapType == 0">
                                        <baidu-map :zoom="zoommap" :center="mapconent" :scroll-wheel-zoom="true">
                                            <!-- 地图缩放控件 -->
                                            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" :offset="{width:10,height:10}"></bm-navigation>
                                            <bm-circle :center="AddData.startcirclePath.startPointCoordinate" :radius="AddData.startcirclePath.startPointRadius" stroke-color="blue" :stroke-opacity="0.5" :stroke-weight="2"></bm-circle>
                                            <bm-view class="mapadress"></bm-view>
                                            <bm-local-search :autoViewport="false" :keyword="AddData.startPoint" :pageCapacity="3" @infohtmlset="mapclick"></bm-local-search>
                                        </baidu-map>
                                    </div>
                                    <div v-if="AddData.mapType == 1" class="AllmapBox">
                                        <baidu-map class="mapBoxs" :zoom="zoommap" :center="mapconent" :scroll-wheel-zoom="true">
                                            <!-- 地图缩放控件 -->
                                            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" :offset="{width:10,height:10}"></bm-navigation>
                                            <bm-marker :position="qsaddress" :dragging="false">
                                                <bm-label content="起点" :labelStyle="{color: 'red', fontSize : '14px'}" :offset="{width: -5, height: 30}"/>
                                            </bm-marker>
                                            <bm-circle :center="AddData.startcirclePath.startPointCoordinate" :radius="AddData.startcirclePath.startPointRadius" stroke-color="blue" :stroke-opacity="0.5" :stroke-weight="2"></bm-circle>
                                        </baidu-map>
                                    </div>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item label="终点名称" prop="zdaddress">
                                <el-col :span="12">
                                    <el-input v-if="AddData.mapType == 0" v-model="AddData.endPoint" placeholder="请输入终点关键词">
                                        <i slot="prefix" class="el-icon-location-outline" style="color:rgba(235, 87, 87, 1);"></i>
                                    </el-input>
                                    <el-input v-if="AddData.mapType == 1" v-model="AddData.endlat" placeholder="请输入终点经纬度" @blur="endclick">
                                        <i slot="prefix" class="el-icon-location-outline" style="color:rgba(235, 87, 87, 1);"></i>
                                    </el-input>
                                    <div class="block" v-if="AddData.mapType == 0">
                                        <span class="demonstration">圆形围栏范围({{valueendmap*100}}米)</span>
                                        <el-slider v-model="valueendmap" :format-tooltip="formatendmap" :max="10" :min="1" style="display: inline-block;width:70%;"></el-slider>
                                    </div>
                                    <div class="block" v-if="AddData.mapType == 1">
                                        <span class="demonstration">圆形围栏范围({{valueendmap*100}}米)</span>
                                        <el-slider v-model="valueendmap" :format-tooltip="formatendmap" :max="10" :min="1" style="display: inline-block;width:70%;"></el-slider>
                                    </div>
                                    <div v-if="AddData.mapType == 0">
                                        <baidu-map :zoom="zoommaptwo" :center="mapconentend" :scroll-wheel-zoom="true">
                                            <!-- 地图缩放控件 -->
                                            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" :offset="{width:10,height:10}"></bm-navigation>
                                            <bm-circle :center="AddData.endcirclePath.endPointCoordinate" :radius="AddData.endcirclePath.endPointRadius" stroke-color="blue" :stroke-opacity="0.5" :stroke-weight="2"></bm-circle>
                                            <bm-view class="endmapsss"></bm-view>
                                            <bm-local-search :autoViewport="false" :keyword="AddData.endPoint" :pageCapacity="3" @infohtmlset="endmapclick"></bm-local-search>
                                        </baidu-map>
                                    </div>
                                    <div v-if="AddData.mapType == 1" class="endAllmapBox">
                                        <baidu-map class="endmapBox" :zoom="zoommaptwo" :center="mapconentend" :scroll-wheel-zoom="true">
                                            <!-- 地图缩放控件 -->
                                            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" :offset="{width:10,height:10}"></bm-navigation>
                                            <bm-circle :center="AddData.endcirclePath.endPointCoordinate" :radius="AddData.endcirclePath.endPointRadius" stroke-color="blue" :stroke-opacity="0.5" :stroke-weight="2"></bm-circle>
                                            <bm-marker :position="zdaddress" :dragging="false">
                                                <bm-label content="终点" :labelStyle="{color: 'red', fontSize : '14px'}" :offset="{width: -5, height: 30}"/>
                                            </bm-marker>
                                        </baidu-map>
                                    </div>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item label="路线呈现" prop="path">
                                <el-col :span="12">
                                    <el-button type="primary" style="margin:6px 0;" @click="asmapClick">双击生成路线</el-button>
                                    <div id="container" v-if="mapShow"></div>
                                    <div id="newtainer" v-if="mapShow"></div>
                                    <!-- <baidu-map class="lushumap" :center="conenters" :zoom="11" v-if="mapShow">
                                        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" :offset="{width:10,height:10}"></bm-navigation>
                                        <bm-driving :start="startadress" :end="endadress" @searchcomplete="handleSearchComplete" :panel="false" :autoViewport="true"></bm-driving>
                                        <bml-lushu
                                            @stop="reset"
                                            :path="AddData.path"
                                            :icon="icon"
                                            :play="play"
                                            :speed="500"
                                            :rotation="true">
                                        </bml-lushu>
                                    </baidu-map> -->
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item label="报警起止时间" prop="time">
                                <el-col :span="7">
                                    <el-time-picker
                                        style="width:100%;"
                                        is-range
                                        v-model="AddData.time"
                                        value-format="HH:mm:ss"
                                        range-separator="至"
                                        start-placeholder="开始时间"
                                        end-placeholder="结束时间"
                                        placeholder="选择时间范围">
                                    </el-time-picker>
                                </el-col>
                            </el-form-item>
                        </el-row> 
                        <el-row>
                            <el-form-item label="围栏相关车辆" prop="lists">
                                <el-col :span="7">
                                    <el-select
                                    v-model="AddData.lists"
                                    multiple
                                    collapse-tags
                                    style="width:100%;"
                                    placeholder="请选择围栏相关车辆">
                                        <el-option
                                        v-for="item in rolelist"
                                        :key="item.id"
                                        :label="item.licensePlate + '-' + item.driverName + '-' + item.phone"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item label="偏移报警距离" prop="warningDistance">
                                <el-col :span="7">
                                    <el-input v-model="AddData.warningDistance" placeholder="请输入报警距离（单位：米）"></el-input>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item label="车程预估时间" prop="estimatedTime">
                                <el-col :span="7">
                                    <el-input v-model="AddData.estimatedTime" placeholder="请输入车程预估时间（单位：分钟）"></el-input>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item label="预估时间偏差" prop="estimatedTimeDeviation">
                                <el-col :span="7">
                                    <el-input v-model="AddData.estimatedTimeDeviation" placeholder="请输入车程预估时间偏差（单位：分钟）"></el-input>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <!-- <el-row>
                            <el-form-item label="允许停留时长" prop="allowStopTime">
                                <el-col :span="7">
                                    <el-input v-model="AddData.allowStopTime" placeholder="请输入停留时间（单位：分钟）"></el-input>
                                </el-col>
                                <el-col :span="2"> <span style="margin-left:10px;"> 分钟</span></el-col>
                            </el-form-item>
                        </el-row> -->
                        <el-row>
                            <el-form-item label="是否为运煤路线" prop="coalTransRoute">
                                <el-col :span="7">
                                    <el-switch v-model="AddData.coalTransRoute">
                                    </el-switch>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item label="围栏排除点">
                                <el-col :span="12">
                                    <el-button type="primary" @click="addPurs">新增排除点</el-button>
                                    <div class="tabbleBox">
                                        <el-table
                                        height="100%"
                                        border
                                        :data="tableData"
                                        :header-cell-style="{ background: '#F6F6F6', color: '#333' }"
                                        style="width: 100%;">
                                            <el-table-column prop="id" v-if="show">
                                            </el-table-column>
                                            <el-table-column label="排除点名称" :show-overflow-tooltip="true" prop="excludeName">
                                            </el-table-column>
                                            <el-table-column label="排除点经度" :show-overflow-tooltip="true" prop="pointLng">
                                            </el-table-column>
                                            <el-table-column label="排除点纬度" :show-overflow-tooltip="true" prop="pointLat">
                                            </el-table-column>
                                            <el-table-column label="排除点范围" :show-overflow-tooltip="true" prop="pointRadius">
                                                <template slot-scope="scope">
                                                    <span v-if="scope.row.pointRadius">{{scope.row.pointRadius}}米</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                            label="操作" fixed="right" width="120">
                                            <template slot-scope="scope">
                                                <span @click="EditPurs(scope.row)" style="color:red;margin-right:8px;cursor: pointer;">编辑</span>
                                                <span @click="delt(scope.row)" style="color:red;cursor: pointer;">删除</span>
                                            </template>
                                            </el-table-column>
                                        <!-- 暂无数据 -->
                                        <div slot="empty">
                                            <p>暂无数据，您可以新增排除点试试</p>
                                        </div>
                                        </el-table>
                                    </div>
                                </el-col>
                            </el-form-item>
                        </el-row>
                    </el-col>
                </el-row>
            </div>
            <!-- 新建车辆信息弹窗 -->
            <el-dialog
                :closeOnClickModal="false"
                title="新增排除点信息"
                :visible.sync="paymentShow"
                width="45%"
                :before-close="paymentShowClose">
                <el-form style="width:95%;" :model="AddDastas" :rules="paymentrules" ref="AddDastas" size="small" label-width="120px">
                    <el-form-item label="排除点名称" prop="excludeName" >
                        <el-input v-model="AddDastas.excludeName" placeholder="请输入排除点名称">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="排除点经度" prop="pointLng" >
                        <el-input v-model="AddDastas.pointLng" @blur="ntLngbklur" placeholder="请输入排除点经度">
                            <i slot="prefix" class="el-icon-location-outline" style="color:#000;"></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="排除点纬度" prop="pointLat">
                            <el-input v-model="AddDastas.pointLat" placeholder="请输入排除点纬度" @blur="tLatbklur">
                                <i slot="prefix" class="el-icon-location-outline" style="color:#000;"></i>
                            </el-input>
                            <div class="block">
                                <span class="demonstration">排除点范围({{valenap*100}}米)</span>
                                <el-slider v-model="valenap" :format-tooltip="forndmap" :max="10" :min="1" style="display: inline-block;width:75%;"></el-slider>
                            </div>
                            <div class="endAllmapBox">
                                <baidu-map class="endmapBox" :zoom="zooms" :center="mapend" :scroll-wheel-zoom="true">
                                    <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" :offset="{width:10,height:10}"></bm-navigation>
                                    <bm-circle :center="AddDastas.srclePath.srdinate" :radius="AddDastas.srclePath.stRadius" stroke-color="blue" :stroke-opacity="0.5" :stroke-weight="2"></bm-circle>
                                    <bm-marker :position="zdess" :dragging="false">
                                        <bm-label content="排除点"  :offset="{width: -5, height: 30}"/>
                                    </bm-marker>
                                </baidu-map>
                            </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="paymentsubmitm('AddDastas')" style="float:right;margin:0 0 0 2%;">确定</el-button>
                        <el-button @click="paymentresetForm" style="float:right;margin:0 0 0 2%;">取消</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
            <!-- 编辑车辆信息弹窗 -->
            <el-dialog
                title="编辑排除点信息"
                :closeOnClickModal="false"
                :visible.sync="editpaymentShow"
                width="45%"
                :before-close="editpaymentShowClose">
                <el-form style="width:95%;" :model="editData" :rules="editpaymentrules" ref="editData" size="small" label-width="100px">
                        <el-form-item label="排除点名称" prop="excludeName" >
                            <el-input v-model="editData.excludeName" placeholder="请输入排除点名称">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="排除点经度" prop="pointLng" >
                            <el-input v-model="editData.pointLng" @blur="editntLngbklur" placeholder="请输入排除点经度">
                                <i slot="prefix" class="el-icon-location-outline" style="color:#000;"></i>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="排除点纬度" prop="pointLat">
                                <el-input v-model="editData.pointLat" placeholder="请输入排除点纬度" @blur="edittLatbklur">
                                    <i slot="prefix" class="el-icon-location-outline" style="color:#000;"></i>
                                </el-input>
                                <div class="block">
                                    <span class="demonstration">排除点范围({{editvalenap*100}}米)</span>
                                    <el-slider v-model="editvalenap" :format-tooltip="editforndmap" :max="10" :min="1" style="display: inline-block;width:75%;"></el-slider>
                                </div>
                                <div class="endAllmapBox">
                                    <baidu-map class="endmapBox" :zoom="editzooms" :center="editmapend" :scroll-wheel-zoom="true">
                                        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" :offset="{width:10,height:10}"></bm-navigation>
                                        <bm-circle :center="editData.srclePath.srdinate" :radius="editData.srclePath.stRadius" stroke-color="blue" :stroke-opacity="0.5" :stroke-weight="2"></bm-circle>
                                        <bm-marker :position="editzdess" :dragging="false">
                                            <bm-label content="排除点"  :offset="{width: -5, height: 30}"/>
                                        </bm-marker>
                                    </baidu-map>
                                </div>
                        </el-form-item>
                    <el-form-item>
                    <el-button type="primary" @click="editpaymentsubmitm('editData')" style="float:right;margin:0 0 0 2%;">确定</el-button>
                    <el-button @click="editpaymentresetForm" style="float:right;margin:0 0 0 2%;">取消</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </div>
        <!-- 内容结束 -->
    </el-form>
</template>

<script>
import {BmlLushu} from 'vue-baidu-map'
import zcar from '../assets/zcar.png';
export default {
    components: {
        BmlLushu
    },
    data() {
        return {
            loading:false,
            mapoptions:[
                {
                    label:'地址检索',
                    value:0
                },
                {
                    label:'经纬度检索',
                    value:1
                }
            ],
            purTitle: "", // 标题
            // 表单验证
            rules: {
                routeName: [
                    { required: true, message: '请输入路线名称', trigger: 'blur' },
                ],
                // qsaddress: [
                //     { required: true, message: '请输入起点名称', trigger: 'change' },
                // ],
                // zdaddress: [
                //     { required: true, message: '请输入终点名称', trigger: 'blur' },
                // ],
                // lists: [
                //     { required: true, message: '请输入相关车辆', trigger: 'blur' },
                // ],
                mapType: [
                    { required: true, message: '请选择起始点类型', trigger: 'blur' },
                ],
                warningDistance: [
                    { required: true, message: '请输入报警距离（单位：米）', trigger: 'blur' },
                    { pattern: /(^[1-9]\d*$)/, message: '报警距离为正整数', trigger: 'blur'} 
                ],
                path: [
                    { required: true, message: '请确认是否规划路线', trigger: 'blur' },
                ],
                time: [
                    { required: true, message: '请选择报警起止时间', trigger: 'blur' },
                ],
                fenceType: [
                    { required: true, message: '请选择围栏类型', trigger: 'blur' },
                ],
                estimatedTime: [
                    { required: true, message: '请输入车程预估时间（单位：分钟）', trigger: 'blur' },
                    { pattern: /(^[1-9]\d*$)/, message: '车程预估时间为正整数', trigger: 'blur'} 
                ],
                estimatedTimeDeviation: [
                    { required: true, message: '请输入车程预估时间偏差（单位：分钟）', trigger: 'blur' },
                    { pattern: /(^[1-9]\d*$)/, message: '车程预估时间偏差为正整数', trigger: 'blur'} 
                ],
                allowStopTime: [
                    { required: true, message: '请输入停留时长（单位：分钟）', trigger: 'blur' },
                    { pattern: /(^[1-9]\d*$)/, message: '停留时长为正整数', trigger: 'blur'} 
                ],
            },
            // 围栏类型选择下拉框
            fenceTypeoptions:[
                { 
                    value: 1,
                    label: '圆形围栏'
                },
                { 
                    value: 2,
                    label: '多边形围栏'
                },
                { 
                    value: 3,
                    label: '道路围栏'
                },
                { 
                    value: 4,
                    label: '行政区围栏'
                },
            ],  
            AddData: {
                mapType:0,
                startlng:'',
                endlat:'',
                lists: [],   
                cars:[],
                fenceType:3,
                startPoint:'',         // 起始地址
                endPoint:'',         // 终点地址               
                time:null,
                path: [],  // 线路经纬度集合
                startcirclePath: {
                    startPointCoordinate: {},
                    startPointRadius: 100
                },
                endcirclePath: {
                    endPointCoordinate: {},
                    endPointRadius: 100
                },
                allowStopTime:60,
                coalTransRoute:true,
            }, // 表单数据
            mapconent:{lng: 116.404, lat: 39.915},
            mapconentend:{lng: 116.404, lat: 39.915},
            valuestartmap:1,
            // startadress:'',             // 开始位置
            qsaddress:{lng: 116.404, lat: 39.915},
            valueendmap:1,
            zoommap:1,
            zoommaptwo:1,
            // endadress:'',               // 结束位置
            zdaddress:{lng: 116.404, lat: 39.915},
            conenters:{},
            mapShow:false,
            play: true,
            rolelist:[],                    // 获取车辆信息列表
            icon: {
                url: zcar,
                size: {width: 40, height: 20},
                opts: {anchor: {width: 18, height:10}}
            },

            // 排除点列表页
            queID:'',               // 唯一标识
            // current:1,              // 当前页数
            // size:5,                // 显示条数
            // total:0,                // 总条数
            show:false,      
            tableData: [],          // 表格数据
            // 新建车辆信息弹窗
            paymentShow:false,
            AddDastas:{
                pointLng:'',
                pointLat:'',
                srclePath: {
                    srdinate: {
                        lng:null,
                        lat:null
                    },
                    stRadius: 100
                },
            },
            valenap:1,  // 排除点范围
            zooms:1,
            mapend:{lng: 116.404, lat: 39.915},  // 地图中心点
            zdess:{lng: 116.404, lat: 39.915},
            paymentrules: {
                excludeName: [
                    { required: true, message: '请输入排除点名称', trigger: 'blur' },
                ],
                ntLngbklur: [
                    { required: true, message: '请输入排除点经度', trigger: 'blur' },
                ],
                pointLat: [
                    { required: true, message: '请输入排除点纬度', trigger: 'blur' },
                ],
            },
            // 车辆编辑
            editpaymentShow:false,
            editData:{
                srclePath: {
                    srdinate: {
                        lng:null,
                        lat:null
                    },
                    stRadius: null
                },
            },
            editvalenap:1,  // 排除点范围
            editzooms:1,
            editmapend:null,  // 地图中心点
            editzdess:null,
            editpaymentrules: {
                excludeName: [
                    { required: true, message: '请输入排除点名称', trigger: 'blur' },
                ],
                ntLngbklur: [
                    { required: true, message: '请输入排除点经度', trigger: 'blur' },
                ],
                pointLat: [
                    { required: true, message: '请输入排除点纬度', trigger: 'blur' },
                ],
            },
        };
    },
    created() {
        let myDate = new Date(new Date('2016-9-10 00:00:00'));
        let str = myDate.toTimeString(); //"10:55:24 GMT+0800 (中国标准时间)"
        let timeStr = str.substring(0,8); //  '10:55:24'
        let myDateone = new Date(new Date('2016-9-10 23:59:59'));
        let strone = myDateone.toTimeString(); //"10:55:24 GMT+0800 (中国标准时间)"
        let timeStrone = strone.substring(0,8); //  '10:55:24'
        this.AddData.time = [timeStr,timeStrone]
        // 模块标题
        this.purTitle = this.$route.meta.title;
        // this.AddData.startPoint = '山西省太原市小店区南中环街529号清控大厦C座12层';             // 开始位置
        // this.AddData.endPoint = '山西省太原市小店区富士康';                 // 结束位置
        // this.AddData.startcirclePath.startPointCoordinate = {lat: 37.800763,lng: 112.552704};
        // this.AddData.endcirclePath.endPointCoordinate = {lat: 37.800763,lng: 112.552704};
        // 车辆信息列表
        // {isBindCar:true,fid:null}
        this.api.riskCar.allData()
        .then(res=>{ 
            if(res.data.code == 200){
                // console.log(res.data.data)
                this.rolelist = res.data.data;
            }
        }) 
        this.queID = this.GetUniqueID();
        this.getpurcon();
    },
    mounted () {
        setTimeout(()=>{
                this.mapconent = {lng: 116.404, lat: 39.915};
                this.mapconentend = {lng: 116.404, lat: 39.915};
                this.mapend = {lng: 116.404, lat: 39.915};
                this.editmapend = {lng: 116.404, lat: 39.915};
        },200)
    },
    methods: {
        /* 
        排除点列表
        */
        // 唯一标识符
        GetUniqueID () {
            // 当前时间转成 36 进制字符串
            var time = Date.now().toString(36)
            // 当前随机数转成 36 进制字符串
            var random = Math.random().toString(36)
            // 去除随机数的 0. 字符串
            random = random.substring(2, random.length)
            // 返回唯一ID
            return random + time
        },
        // 表格数据 
        getpurcon(){
            // 渲染表格
            this.api.cfence.allData({batch_no:this.queID})
            .then(res=>{
                if(res.data.code == 200){
                    // console.log(res.data.data)
                    this.tableData = res.data.data;
                }
            })
        },
        // 经度
        ntLngbklur(){
            if(this.AddDastas.pointLng && this.AddDastas.pointLat){
                this.zooms = 19;
                this.mapend = {
                    lng:this.AddDastas.pointLng*1,
                    lat:this.AddDastas.pointLat*1  
                }
                this.zdess = {
                    lng:this.AddDastas.pointLng*1,
                    lat:this.AddDastas.pointLat*1   
                };
                this.$set(this.AddDastas.srclePath.srdinate,'lng',(this.AddDastas.pointLng)*1)
                this.$set(this.AddDastas.srclePath.srdinate,'lat',(this.AddDastas.pointLat)*1)
                this.$set(this.AddDastas.srclePath,'stRadius',100);
                this.$forceUpdate();
            }
        },
        // 纬度
        tLatbklur(){
            if(this.AddDastas.pointLng && this.AddDastas.pointLat){
                this.zooms = 19;
                this.mapend = {
                    lng:this.AddDastas.pointLng*1,
                    lat:this.AddDastas.pointLat*1  
                }
                this.zdess = {
                    lng:this.AddDastas.pointLng*1,
                    lat:this.AddDastas.pointLat*1   
                };
                this.$set(this.AddDastas.srclePath.srdinate,'lng',this.AddDastas.pointLng*1)
                this.$set(this.AddDastas.srclePath.srdinate,'lat',this.AddDastas.pointLat*1)
                this.$set(this.AddDastas.srclePath,'stRadius',100);
                this.$forceUpdate();
            }
        },
        // 排除点范围
        forndmap(val) {
            this.$set(this.AddDastas.srclePath,'stRadius',val*100);
            this.$forceUpdate();
            return val*100;
        },
        // 新建车辆信息
        addPurs(){
            this.paymentShow = true;
            this.valenap = 1;
        },
        // 新建车辆信息取消
        paymentShowClose(){
            this.paymentShow = false;
            this.AddDastas = {
                pointLng:'',
                pointLat:'',
                srclePath: {
                    srdinate: {
                        lng:null,
                        lat:null
                    },
                    stRadius: 100
                },
            };
            this.getpurcon();
        },
        // 取消新建车辆信息提交
        paymentresetForm() {
            this.paymentShow = false;
            this.AddDastas = {
                pointLng:'',
                pointLat:'',
                srclePath: {
                    srdinate: {
                        lng:null,
                        lat:null
                    },
                    stRadius: 100
                },
            };
            this.getpurcon();
      },
        // 新建车辆信息提交
        paymentsubmitm(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                this.AddDastas.coalTransRoute = true;
                this.AddDastas.fenceType = 1;
                this.AddDastas.pointRadius = this.AddDastas.srclePath.stRadius;
                this.AddDastas.batchNo = this.queID;
                this.api.cfence.add(this.AddDastas)
                .then(res=>{
                    if(res.data.code == 200){
                        this.$message({
                            type: 'success',
                            message: '新增排除点成功!'
                        });
                        this.AddDastas = {
                            pointLng:'',
                            pointLat:'',
                            srclePath: {
                                srdinate: {
                                    lng:null,
                                    lat:null
                                },
                                stRadius: 100
                            },
                        }
                        this.tableData = [];
                        this.getpurcon();
                        this.paymentShow = false;
                    }else if(res.data.code == 602){
                        this.$message({
                            type: 'warning',
                            message: res.data.msg
                        });
                    }
                })
            }
            });
        },

        // 编辑经度
        editntLngbklur(){
            if(this.editData.pointLng && this.editData.pointLat){
                this.editzooms = 19;
                this.editmapend = {
                    lng:this.editData.pointLng*1,
                    lat:this.editData.pointLat*1  
                }
                this.editzdess = {
                    lng:this.editData.pointLng*1,
                    lat:this.editData.pointLat*1   
                };
                this.$set(this.editData.srclePath.srdinate,'lng',(this.editData.pointLng)*1)
                this.$set(this.editData.srclePath.srdinate,'lat',(this.editData.pointLat)*1)
                this.$set(this.editData.srclePath,'stRadius',100);
                this.$forceUpdate();
            }
        },
        // 编辑纬度
        edittLatbklur(){
            if(this.editData.pointLng && this.editData.pointLat){
                this.editzooms = 19;
                this.editmapend = {
                    lng:this.editData.pointLng*1,
                    lat:this.editData.pointLat*1  
                }
                this.editzdess = {
                    lng:this.editData.pointLng*1,
                    lat:this.editData.pointLat*1   
                };
                this.$set(this.editData.srclePath.srdinate,'lng',(this.editData.pointLng)*1)
                this.$set(this.editData.srclePath.srdinate,'lat',(this.editData.pointLat)*1)
                this.$set(this.editData.srclePath,'stRadius',100);
                this.$forceUpdate();
            }
        },
        // 编辑排除点范围
        editforndmap(val) {
            this.$set(this.editData.srclePath,'stRadius',val*100);
            this.$forceUpdate();
            return val*100;
        },
        // 编辑车辆信息
        EditPurs(data){
            this.editpaymentShow = true;
            this.editData = JSON.parse(JSON.stringify(data));
            this.editzooms = 19;
            this.editmapend = {
                lng:this.editData.pointLng*1,
                lat:this.editData.pointLat*1  
            }
            this.editzdess = {
                lng:this.editData.pointLng*1,
                lat:this.editData.pointLat*1   
            };
            this.editData.srclePath = {
                srdinate:{
                    lng:this.editData.pointLng*1,
                    lat:this.editData.pointLat*1
                },
                stRadius:this.editData.pointRadius
            }
            this.editvalenap = (this.editData.pointRadius/100)*1;
        },
        // 编辑车辆信息取消
        editpaymentShowClose(){
            this.editpaymentShow = false;
            this.editData = {
                pointLng:'',
                pointLat:'',
                srclePath: {
                    srdinate: {
                        lng:null,
                        lat:null
                    },
                    stRadius: null
                },
            };
            this.getpurcon();
        },
        // 取消新建车辆信息提交
        editpaymentresetForm() {
            this.editpaymentShow = false;
            this.editData = {
                pointLng:'',
                pointLat:'',
                srclePath: {
                    srdinate: {
                        lng:null,
                        lat:null
                    },
                    stRadius: null
                },
            };
            this.getpurcon();
        },
        // 编辑车辆信息提交
        editpaymentsubmitm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.editData.pointRadius = this.editData.srclePath.stRadius;
                    this.api.cfence.edit(this.editData)
                    .then(res=>{
                        if(res.data.code == 200){
                            this.$message({
                                type: 'success',
                                message: '编辑排除点成功!'
                            });
                            this.editData = {
                                pointLng:'',
                                pointLat:'',
                                srclePath: {
                                    srdinate: {
                                        lng:null,
                                        lat:null
                                    },
                                    stRadius: null
                                },
                            };
                            this.tableData = [];
                            this.getpurcon();
                            this.editpaymentShow = false;
                        }
                    })
                }else if(res.data.code == 602){
                    this.$message({
                    type: 'warning',
                    message: res.data.msg
                    });
                }
            });
        },

        // 删除排除点
        delt(data){
            this.api.cfence.del({id:data.id})
            .then(res=>{
                if(res.data.code == 200){
                    this.$message({
                        type: 'success',
                        message: '删除排除点成功!'
                    });
                    this.tableData = [];
                    this.getpurcon();
                }
            })
        },

        // 起始点类型
        qkmap(){
            this.AddData.startlng = '';
            this.AddData.startPoint = '';
            this.AddData.endlat = '';
            this.AddData.endPoint = '';
            this.zdaddress = '';
            this.qsaddress = {lng: 116.404, lat: 39.915}
            this.zdaddress = {lng: 116.404, lat: 39.915}
            this.zoommap = 1;
            this.zoommaptwo = 1;
            this.AddData.startcirclelnglat = {
                startPointCoordinate: {},
                startPointRadius: 100
            };
            this.AddData.endcirclePath = {
                endPointCoordinate: {},
                endPointRadius: 100
            };
            this.valuestartmap = 1;
        },
        // 起点输入框
        starclick(){
            if(this.AddData.startlng){
                let mapdata = this.AddData.startlng.split(',');
                this.zoommap = 18;
                this.mapconent = {
                    lng:mapdata[0]*1,
                    lat:mapdata[1]*1   
                }
                this.qsaddress = {
                    lng:mapdata[0]*1,
                    lat:mapdata[1]*1
                };
                this.$set(this.AddData.startcirclePath.startPointCoordinate,'lng',mapdata[0]*1)
                this.$set(this.AddData.startcirclePath.startPointCoordinate,'lat',mapdata[1]*1)
                this.$set(this.AddData.startcirclePath,'startPointRadius',100);
            }
        },
        // 终点输入框
        endclick(){
            // console.log(this.AddData.endlat)
            if(this.AddData.endlat){
                let mapdata = this.AddData.endlat.split(',');
                this.zoommaptwo = 18;
                this.mapconentend = {
                    lng:mapdata[0]*1,
                    lat:mapdata[1]*1   
                }
                this.zdaddress = {
                    lng:mapdata[0]*1,
                    lat:mapdata[1]*1
                };
                this.$set(this.AddData.endcirclePath.endPointCoordinate,'lng',mapdata[0]*1)
                this.$set(this.AddData.endcirclePath.endPointCoordinate,'lat',mapdata[1]*1)
                this.$set(this.AddData.endcirclePath,'startPointRadius',100);
            }
        },
        reset () {
            this.play = false;
        },
        handleSearchComplete (res) {
            // console.log(res.getPlan(0).getRoute(0).getPath())
            this.AddData.path = res.getPlan(0).getRoute(0).getPath();
            if(this.AddData.path){
                this.conenters = this.AddData.path[this.AddData.path.length*1/2];
            }
        },
        // 起始位置
        mapclick(e){
            // console.log(e)
            this.zoommap = 18;
            this.qsaddress = e.pointN;
            this.AddData.startPoint = e.address;
            this.AddData.startcirclePath.startPointCoordinate = e.point;
            this.mapconent = e.pointN
            // this.startadress = this.AddData.keyword;            // 开始位置
        },
        formatTooltip(val) {
            this.AddData.startcirclePath.startPointRadius = val*100;
            this.$forceUpdate();
            return val * 100;
        },
        // 终点位置
        endmapclick(e){
            // console.log(e)
            this.zoommaptwo = 18;
            this.AddData.endPoint = e.address;
            this.zdaddress = e.pointN;
            this.AddData.endcirclePath.endPointCoordinate = e.point;
            this.mapconentend = e.pointN
            // this.endadress = this.AddData.endkeyword;             // 结束位置
        },
        formatendmap(val) {
            this.AddData.endcirclePath.endPointRadius = val*100;
            return val * 100;
        },
        // 路线绘制事件
        asmapClick(){
            this.play = true;
            this.mapShow = true;
            if(this.qsaddress && this.zdaddress){
                var map = new BMap.Map("container");
                map.centerAndZoom(new BMap.Point(this.qsaddress.lng,this.qsaddress.lat), 18);
                // map.enableScrollWheelZoom(true);  //启用滚轮放大缩小
                var scaleCtrl = new BMap.ScaleControl();  // 添加比例尺控件
                map.addControl(scaleCtrl);
                map.addControl(new BMap.NavigationControl(
                    {
                        type : BMAP_NAVIGATION_CONTROL_ZOOM, //缩放控件类型 仅包含缩放按钮
                        anchor : BMAP_ANCHOR_BOTTOM_RIGHT, //右下角
                        offset : new BMap.Size(1,1) //进一步控制缩放按钮的水平竖直偏移量
                    }
                ));
                var p1 = new BMap.Point(this.qsaddress.lng,this.qsaddress.lat);
                var p2 = new BMap.Point(this.zdaddress.lng,this.zdaddress.lat);
                // var p1 = new BMap.Point(111.945449,37.100216);
                // var p2 = new BMap.Point(111.985855,37.056028);
                
                var driving = new BMap.DrivingRoute(map,{renderOptions:{map:map,autoViewport:true}});
                driving.search(p1, p2);  // ,{waypoint:['灵石县勇勇超市']}

                this.newmap();
            }else{
                this.$message({
                    message: '起始点和终点不能为空！',
                    type: 'warning'
                });
            }
            // this.conenters = this.AddData.path[0];
            // if(this.AddData.startPoint && this.AddData.endPoint){
            //     this.startadress = this.AddData.startPoint;             // 开始位置
            //     this.endadress = this.AddData.endPoint;                 // 结束位置
            //     console.log(this.startadress)
            //     console.log(this.endadress)
            // }else{
            //     this.$message({
            //     message: '起始点或终点不能为空！',
            //     type: 'warning'
            //     });
            // }
        },
        newmap(){
            var map = new BMap.Map("newtainer");
            map.centerAndZoom(new BMap.Point(this.qsaddress.lng,this.qsaddress.lat), 18);
            var p1s = new BMap.Point(this.qsaddress.lng,this.qsaddress.lat);
            var p2s = new BMap.Point(this.zdaddress.lng,this.zdaddress.lat);
            var drivingss = new BMap.DrivingRoute(map,{onSearchComplete:results=>{
                    if (drivingss.getStatus() == BMAP_STATUS_SUCCESS) {
                        var plan = results.getPlan(0);
                        var ptss = drivingss.getResults().getPlan(0).getRoute(0).getPath(); //通过驾车实例，获得一系列点的数组
                        for(let key in ptss){
                            this.AddData.path.push(ptss[key])
                        }
                        var dis = plan.getDistance(false);      // 获取总公里数(米)
                        var t = plan.getDuration(false);        // 获取总耗时（秒）
                        console.log(dis,t)
                    }
            }},{renderOptions:{map:map,autoViewport:true}});
            drivingss.search(p1s, p2s);  // ,{waypoint:['灵石县勇勇超市']}
            // console.log(this.AddData.path)
        },

        //新建信息表单提交
        AddDatasubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    if(this.AddData.warningDistance > 200){
                        this.$message({
                            type: "warning",
                            message: "报警偏移距离不能超过200米!",
                        });
                    }else{
                        for(let key in this.AddData.lists){
                            for(let kv in this.rolelist){
                                if(this.AddData.lists[key] == this.rolelist[kv].id){
                                    this.AddData.cars.push({id:this.rolelist[kv].id,licensePlate:this.rolelist[kv].licensePlate})
                                }
                            }
                        }
                        this.AddData.fenceCoordType = 'bd09ll';
                        this.AddData.alarmStartTime = this.AddData.time[0];
                        this.AddData.alarmEndTime = this.AddData.time[1];
                        this.AddData.excFence = this.tableData;
                        this.api.riskElect.add(this.AddData)
                        .then(res=>{
                            if(res.data.code == 200){
                                this.loading = false;
                                var that = this
                                that.$message({
                                    type: "success",
                                    message: "保存电子围栏成功!",
                                    duration:500,  
                                    onClose(){
                                        that.$router.push({name:"ElectronicFence"})
                                    }
                                });
                            }
                        })
                    }

                }
            });
        },
        // 取消保存表单
        Addopen() {
            this.$router.push({name:"ElectronicFence"})
        },
    },
    computed: {},
    watch: {}
};
</script>
<style lang="less" scoped>
.tabbleBox{
    width: 100%;
    height:240px;
    margin-top:14px;
}
#newtainer{
    width: 0px;
    height: 0px;
}
#container{
    width: 100%;
    height: 400px;
}
.mapadress{
    width: 100%;
    height: 400px;
}
.AllmapBox{
    width: 100%;
    height: 400px;
    .mapBoxs{
        width: 100%;
        height: 100%;
    }
}

.endAllmapBox{
    width: 100%;
    height: 400px;
    .endmapBox{
        width: 100%;
        height: 100%;
    }
}
.endmapsss{
    width: 100%;
    height: 400px;
    margin-top:12px;  
}
.lushumap{
    width: 100%;
    height: 500px;
}
// 头部开始
.pur-top {
    width: 100%;
    height: 50px;
    padding: 9px 24px 9px 32px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
    .pur-size {
        font-size: 16px;
        line-height: 32px;
        color: #333;
        font-weight: 600;
    }
    .pur-right {
        .el-button {
            margin-left: 16px;
            float: right;
        }
    }
}
// 头部结束
// 表单内容开始
.pur-nav {
    height: calc(100vh - 110px);
    background-color: #fff;
    margin: 0 60px;
    box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
    padding:33px;
    box-sizing: border-box;
    .pur-table {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        .see-top {
            height: 40px;
            font-weight: 600;
            font-size: 14px;
            line-height: 45px;
            margin-bottom:24px;
            div{
                width: 2px;
                height:15px;
                margin:15px 6px 0 0;
                background-color: #EA222E;
                float: left;
            }
        }
        .see-lable {
            padding: 15px 0;
            .el-input{
                .input{
                    width: 430px;
                }
            }
        }
    }
    .block{
        .demonstration{
            float: left;
            width: 25%;
        }
        margin-top:2%;
    }
}
.AddPurIcon{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #828282;
    color: #828282;
    font-size:12px;
    text-align: center;
    line-height: 16px;
    margin-top: 14px;
    margin-left: -25px;
}
.AddbuttonUpload{
    width: 100px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    font-size: 14px;
    color: #666;
    line-height: 32px;
    text-align: center;
}
.AddbuttonUpload:hover{
    background: #e4e4e4;
    color: #333;
}
// 表单内容结束
</style>